import React from 'react';
import contactAddress from '../components/contactAddress';
import SEO from '../components/seo';

export default ({ location }) => {

	return (
		<>
			<SEO
				title={"Contact Us"}
				description={"We value your input! If you have any inquiries, please don't hesitate to get in touch with us"}
				keywords={"contact"}
				canonical={location.href}
			/>
			<p>
				At SKARP Technologies we believe in inovation, being open minded and responsive. Our primary goal is to be at the cutting edge and define the technology of tomorrow, today.
			</p>
			<p>
				A big part of this comes from listning to the wants and needs of people and industries we are working hard to help. We value your input! If you have any inquiries, please don't hesitate to use the contact form bellow.
			</p>

			<h3>
				Mailing address
			</h3>
			<strong>
				{contactAddress.name}
			</strong>
			<p>
				{contactAddress.street}<br />
				{contactAddress.office}<br />
				{contactAddress.county}, {contactAddress.state} {contactAddress.zip}<br />
				{contactAddress.country}
			</p>
			<br />
		</>
	)
}